import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import { RepositoryFactory } from "./repositories/RepositoryFactory";
import chat from "./store/chat";
import auth from "./store/auth";
import tarif from "./store/tarif";
import rechner from "./store/rechner";
import basic from "./store/basic";
import user from "./store/user";
import guest from "./store/guest";

export default new Vuex.Store({
  modules: {
    chat: chat,
    auth: auth,
    tarif: tarif,
    rechner: rechner,
    basic: basic,
    user: user,
    guest: guest,
  },
  state: {
    backendHost:
      process.env.NODE_ENV === "production"
        ? "http://www.waizmannpro.de"
        : "http://loc.waizmannpro.de",
    loading_full: false,
    loading: false,
    chatview: false,
    navview: false,
    isMobileStore: false,
  },
  mutations: {
    setIsMobile(state) {
      state.isMobileStore = true;
    },
    set_nav_view_open(state) {
      state.navview = true;
    },
    set_nav_view_close(state) {
      state.navview = false;
    },
    set_chat_window(state) {
      state.chatview = true;
    },
    start_loading_full(state) {
      state.loading_full = true;
      state.status = "Start Loading";
    },
    stop_loading_full(state) {
      state.loading_full = false;
      state.status = "loaded";
    },
    start_loading(state) {
      state.loading = true;
      state.status = "Start Loading";
    },
    stop_loading(state) {
      state.loading = false;
      state.status = "loaded";
    },
    success(state, status) {
      state.status = status;
    },
    error(state, status) {
      state.status = status;
    },
  },
  actions: {
    getDrawerState() {
      return true;
    },
    stopFullLoader({ commit }) {
      commit("stop_loading_full");
    },
    startFullLoader({ commit }) {
      commit("start_loading_full");
    },
    start_loading({ commit }) {
      commit("start_loading");
    },
    stop_loading({ commit }) {
      commit("stop_loading");
    },
  },
  getters: {
    isNavView: (state) => state.navview,
    isChatWindow: (state) => state.chatview,
    isLoading: (state) => state.loading,
    isLoadingFull: (state) => state.loading_full,
    showStoreMobile: (state) => state.isMobileStore,
  },
});
