import Repository from "./Repository";

const resource = "";

export default {
  apiUserGet(action) {
    if (action == "checklogin") {
      return Repository.get(`${resource}/user/checklogin`);
    }
    if (action == "startlogout") {
      return Repository.get(`${resource}/user/startlogout`);
    }
  },
  apiUserPost(payload) {
    if (payload["action"] == "startlogin") {
      return Repository.post(`${resource}/user/startlogin`, { payload });
    }
    if (payload["action"] == "insertuser") {
      return Repository.post(`${resource}/user/insertuser`, { payload });
    }
    if (payload["action"] == "updateuser") {
      return Repository.post(`${resource}/user/updateuser`, { payload });
    }
    if (payload["action"] == "passforgot") {
      return Repository.post(`${resource}/user/passforgot`, { payload });
    }
    if (payload["action"] == "updateuserpasswort") {
      return Repository.post(`${resource}/user/updateuserpasswort`, {
        payload,
      });
    }
    if (payload["action"] == "bestellungen") {
      return Repository.post(`${resource}/user/getbestellungen`, { payload });
    }
    if (payload["action"] == "insertbestellung") {
      return Repository.post(`${resource}/user/insertbestellung`, { payload });
    }
    if (payload["action"] == "insertbestellungtoken") {
      return Repository.post(`${resource}/user/insertbestellungtoken`, {
        payload,
      });
    }
    if (payload["action"] == "startlogintoken") {
      return Repository.post(`${resource}/user/startlogintoken`, { payload });
    }
  },
};
