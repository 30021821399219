<template>
  <div>
    <span v-if="type == 'ergebnis'">
      <h2>
        Ihr Ergebnis für
        <span v-if="berechnung.ber_name != '' && berechnung.ber_tierart == 2"
          >Ihre Katze</span
        >
        <span v-if="berechnung.ber_name != '' && berechnung.ber_tierart == 1"
          >Ihren Hund</span
        >:
        <span v-if="berechnung.ber_name != ''" class="green--text">
          {{ berechnung.ber_name }}
        </span>
        <span v-if="berechnung.ber_name == '' && berechnung.ber_tierart == 2">
          <b>Katze, {{ berechnung.ber_alter }} Jahre</b>
        </span>
        <span v-if="berechnung.ber_name == '' && berechnung.ber_tierart == 1">
          <span v-if="berechnung.ber_mischling == 1" class="green--text"
            >Mischling</span
          >
          <span v-if="berechnung.ber_mischling == 0" class="green--text">
            <b v-if="berechnung.rasse">{{ berechnung.rasse }}</b>
            <b v-if="!berechnung.rasse"
              >Hund, {{ berechnung.ber_alter }} Jahre</b
            ></span
          >
        </span>
      </h2>
    </span>

    <span v-if="!type">
      <span v-if="berechnung.ber_name != ''">
        <b>{{ berechnung.ber_name }}</b
        ><br />
      </span>
      <span v-if="berechnung.ber_name == '' && berechnung.ber_tierart == 1">
        <b
          >Katze, {{ berechnung.ber_alter }} Jahre
          <span v-if="nameRase1">, {{ nameRase1 }}</span>
        </b>
      </span>
      <span v-if="berechnung.ber_name == '' && berechnung.ber_tierart == 1">
        <span v-if="berechnung.ber_mischling == 1">
          <b>Mischling</b><br />
          <span v-if="nameRase1"> {{ nameRase1 }} </span>
          <span v-if="nameRase2"> und {{ nameRase2 }} </span>
        </span>
        <span v-if="berechnung.ber_mischling == 0">
          <b v-if="nameRase1">{{ nameRase1 }}</b>
          <b v-if="!nameRase1">Hund, {{ berechnung.ber_alter }} Jahre</b>
          <br
        /></span>
      </span>
    </span>
  </div>
</template>

<script>
  export default {
    name: "rechner-name",
    props: ["berechnung", "type", "nameRase1", "nameRase2"],
    components: {},
    data: function() {
      return {
        showVorgabe: false,
        rassen: false,
        fullrassen: false,
      };
    },
    created() {},
    methods: {},
  };
</script>
