import { RepositoryFactory } from '../repositories/RepositoryFactory';

const authRepository = RepositoryFactory.get('auth')


const auth = {
    namespaced: true,
    state: {
        guest: null,
        error: null
    },
    mutations: {
        setGuest (state, guest) {
            state.guest = guest
        },
        error (state, status) {
            state.error = status
        }
    },
    actions: {
        getClient ({ commit }) {
            /*  commit('start_loading') */
            return authRepository.getClient()
                .then(resp => {
                    commit('success')
                    return resp;
                })
                .catch(err => {
                    commit('error')
                    return err;
                }).finally(() => { /* commit('stop_loading') */ });
        }
    },
    getters: {
        guest: state => state.guest,
    }

}

export default auth