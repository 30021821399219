import axios from "axios";

/* let backendHost = process.env.NODE_ENV === 'production'
  ?'https://www.waizmanntabelle.de'
  :'http://loc.waizmanntabelle.de'
 */

var baseURL = "https://loc.holsteiner.de/api";
// You can use your own logic to set your local or production domain

if (window.location.hostname == "localhost") {
  baseURL = "https://loc.holsteiner.de/api";
}
if (window.location.hostname == "www.eisbaumtabelle.de") {
  baseURL = "https://www.eisbaumtabelle.de/api";
}
if (window.location.hostname == "loc.holsteiner.de") {
  baseURL = "https://loc.holsteiner.de/api";
}

export default axios.create({
  baseURL: baseURL,
  withCredentials: true,
});
