import { RepositoryFactory } from '../repositories/RepositoryFactory';

const guestRepository = RepositoryFactory.get('guest')



const chat = {
    namespaced: true,
    state: {

        online: false,
        guest: null,
        loading: 0,
    },
    mutations: {
        start_loading (state) {
            state.loading += 1;
            state.status = 'Start Loading'
        },
        stop_loading (state) {
            state.loading -= 1;
            state.status = 'loaded'
        },

        setGuest (state, guest) {
            state.guest = guest
        },
        success (state, status) {
            state.status = status
        },
        error (state, status) {
            state.status = status
        },
    },
    actions: {

        setGuest ({ commit }, guest) {
            commit('setGuest', guest)
        },
        getGuest ({ commit }) {
            commit('start_loading')
            return guestRepository.getGuest()
                .then(resp => {
                    commit('setGuest', resp.data)
                    commit('success')
                    return resp.data;
                })
                .catch(err => {
                    commit('error', err)
                    return err;
                }).finally(() => { commit('stop_loading') });
        },

        getOldChats ({ commit }, data) {
            console.log('store ma', data)
            return guestRepository.getOldChats(data)
                .then(resp => {
                    commit('setGuest', resp.data)
                    commit('success')
                    return resp.data;
                })
                .catch(err => {
                    commit('error', err)
                    return err;
                }).finally(() => { commit('start_loading') });
        },
        getMitarbeiter ({ commit }, data) {
            console.log('store ma', data)
            return guestRepository.getMitarbeiter(data)
                .then(resp => {
                    commit('setGuest', resp.data)
                    commit('success')
                    return resp.data;
                })
                .catch(err => {
                    commit('error', err)
                    return err;
                }).finally(() => { commit('start_loading') });
        },


        updateGuest ({ commit }, data) {
            commit('start_loading')
            return guestRepository.updateGuest(data)
                .then(resp => {
                    commit('setGuest', resp.data)
                    commit('success')
                    return resp.data;
                })
                .catch(err => {
                    commit('error')
                    return err;
                }).finally(() => { commit('stop_loading') });
        },
    },
    getters: {

        guest: state => state.guest
    } // end getters

}

export default chat

