<template>
  <div>
    <ul class="my-4">
      <li v-if="berechnung.ber_tierart == 1">
        Alter Ihres Hundes: {{ berechnung.ber_alter }} Jahre (Geburtstag:
        {{ berechnung.ber_geburtstag }})
      </li>
      <li v-if="berechnung.ber_tierart == 2">
        Alter Ihrer Katze: {{ berechnung.ber_alter }} Jahre (Geburtstag:
        {{ berechnung.ber_geburtstag }})
      </li>
      <li v-if="berechnung.ber_geschlecht == 1">
        männlich
      </li>
      <li v-if="berechnung.ber_geschlecht == 2">
        weiblich
      </li>
      <li v-if="berechnung.ber_kastration == 1">
        kastriert/sterilisert
      </li>
      <li v-if="berechnung.ber_outdoor == 1">
        Freigänger
      </li>
      <li v-if="berechnung.ber_plz != ''">
        Ihre Postleitzahl: {{ berechnung.ber_plz }}
      </li>
      <li v-if="berechnung.ber_mischling == 1 && berechnung.ber_tierart == 1">
        Mischling
      </li>
      <li v-if="berechnung.ber_mischling == 0 && berechnung.ber_tierart == 1">
        reinrassiger Hund
      </li>
      <li v-if="nameRase1 && berechnung.ber_tierart == 1">
        Rasse: {{ nameRase1 }}
      </li>
      <li v-if="nameRase2 && berechnung.ber_tierart == 1">
        2. Rasse: {{ nameRase2 }}
      </li>
      <li v-if="berechnung.ber_hoehe == 1 && berechnung.ber_tierart == 1">
        Ihr Hund ist 0-44 cm hoch.
      </li>
      <li v-if="berechnung.ber_hoehe == 2 && berechnung.ber_tierart == 1">
        Ihr Hund größer als 45 cm.
      </li>
      <li v-if="berechnung.ber_jagd == 1 && berechnung.ber_tierart == 1">
        Hund wird in der Jagd eingesetzt
      </li>
      <li v-if="berechnung.ber_chiped == 0 && berechnung.ber_tierart == 1">
        Hund ist noch nicht gechiped
      </li>
      <li v-if="berechnung.ber_chiped == 0 && berechnung.ber_tierart == 2">
        Katze ist noch nicht gechiped
      </li>

      <li v-if="berechnung.ber_homoe == 1">
        Homöopathie soll mit versichert werden
      </li>
      <!--  -->
      <li
        v-if="berechnung.ber_annahme_op6m == 1 && berechnung.ber_tierart == 1"
      >
        In den letzten 6 Monate wurde <b>eine</b> OP durchgeführt
      </li>
      <li
        v-if="berechnung.ber_annahme_op6m == 2 && berechnung.ber_tierart == 1"
      >
        In den letzten 6 Monate wurde keine OP durchgeführt
      </li>

      <!--  -->
      <li
        v-if="berechnung.ber_annahme_krank == 2 && berechnung.ber_tierart == 1"
      >
        Hund ist gesund
      </li>
      <li
        v-if="berechnung.ber_annahme_krank == 1 && berechnung.ber_tierart == 1"
      >
        Hund ist aktuell krank
      </li>

      <li
        v-if="berechnung.ber_annahme_krank == 2 && berechnung.ber_tierart == 2"
      >
        Katze ist gesund
      </li>
      <li
        v-if="berechnung.ber_annahme_krank == 1 && berechnung.ber_tierart == 2"
      >
        Katze ist aktuell krank
      </li>

      <li v-if="berechnung.ber_chiped == 1 && berechnung.ber_tierart == 1">
        Hund ist gechiped
      </li>
      <li v-if="berechnung.ber_impfung == 1 && berechnung.ber_tierart == 1">
        Hund ist geimpft
      </li>
      <li v-if="berechnung.ber_impfung == 0 && berechnung.ber_tierart == 1">
        Hund noch nicht geimpft
      </li>
      <li v-if="berechnung.ber_chiped == 1 && berechnung.ber_tierart == 2">
        Katze ist gechiped
      </li>
      <li v-if="berechnung.ber_impfung == 1 && berechnung.ber_tierart == 2">
        Katze ist geimpft
      </li>
      <li v-if="berechnung.ber_impfung == 0 && berechnung.ber_tierart == 2">
        Katze noch nicht geimpft
      </li>
    </ul>

    <v-btn class="nocaps" outlined blue v-if="!type">
      <a :href="`/vergleich/${berechnung.ber_token}`"
        >Berechnungsergebnis öffnen</a
      ></v-btn
    >
  </div>
</template>

<script>
  import RechnerName from "./RechnerName";

  export default {
    name: "rechner-vorgabe",
    props: ["berechnung", "type"],
    components: {
      RechnerName,
    },
    data: function() {
      return {
        nameRase1: false,
        nameRase2: false,
      };
    },
    created() {
      if (
        this.berechnung.ber_rasse_id > 0 ||
        this.berechnung.ber_rasse_id_2 > 0
      ) {
        this.getRassen();
      }
    },
    methods: {
      getRassen() {
        this.$store
          .dispatch("getRassen")
          .then((resp) => {
            if (resp.data.rassen) {
              this.rassen = resp.data.rassen;
              this.fullrassen = resp.data.fullrassen;
              if (this.berechnung.ber_rasse_id > 0) {
                this.nameRase1 = this.searchStringInArray(
                  this.berechnung.ber_rasse_id
                );
              }
              if (this.berechnung.ber_rasse_id_2 > 0) {
                this.nameRase2 = this.searchStringInArray(
                  this.berechnung.ber_rasse_id_2
                );
              }
            }
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },
      searchStringInArray(rasse_id) {
        for (var j = 0; j < this.rassen.length; j++) {
          if (this.fullrassen[j].ras_id == rasse_id)
            return this.fullrassen[j].ras_name;
        }
        return -1;
      },
    },
  };
</script>
