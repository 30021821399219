import { RepositoryFactory } from '../repositories/RepositoryFactory';

const guestRepository = RepositoryFactory.get('guest')

const guest = {
    namespaced: false,
    state: {
        error: null,
        guest: null,
    },
    mutations: {
        error(state, status) {
            state.error = status
        },
        setGuest(state) {
            state.guest = state;
            console.log('guest', state.guest)

        },
    },
    actions: {
        apiGuestGet({ commit }, action) {
            // console.log('Store apiGuestGet');
            return guestRepository.apiGuestGet(action)
                .then(resp => {
                    commit('success')

                    return resp;
                })
                .catch(err => {
                    commit('error')
                    return err;
                }).finally(() => { });
        },

        checkAdmin({ commit }) {
            return guestRepository.checkAdmin()
                .then(resp => {
                    commit('success')

                    return resp;
                })
                .catch(err => {
                    commit('error')
                    return err;
                }).finally(() => { });
        },


    },
    getters: {
        getGuestStore: state => state.guest,


    }

}

export default guest