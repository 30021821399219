import { RepositoryFactory } from "../repositories/RepositoryFactory";

const userRepository = RepositoryFactory.get("user");

const user = {
  namespaced: false,
  state: {
    error: null,
    user: false,
  },
  mutations: {
    error(state, status) {
      state.error = status;
    },
    user(state, user) {
      state.user = user;
    },
  },
  actions: {
    apiUserGet({ commit }, action) {
      // console.log('Store User apiUserGet', action);
      return userRepository
        .apiUserGet(action)
        .then((resp) => {
          commit("success");

          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },

    apiUserPost({ commit }, payload) {
      return userRepository
        .apiUserPost(payload)
        .then((resp) => {
          commit("success");

          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },
  },
  getters: {
    getUserStore: (state) => state.user,
  },
};

export default user;
