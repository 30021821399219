import Vue from "vue";
import App from "./App.vue";
import store from "./store.js";

import Vue2TouchEvents from "vue2-touch-events";
Vue.use(Vue2TouchEvents);

import vuetify from "@/plugins/vuetify"; // path to vuetify export

import VueRouter from "vue-router";

import VueLogger from "vuejs-logger";

import routes from "./routes";
import tooltippsjson from "./data/tooltipps.json";
import "./css/mystyle.css";

// import './firebase'
// import { firestorePlugin } from 'vuefire'

import VTooltip from "v-tooltip";

Vue.use(VTooltip);

const isProduction = process.env.NODE_ENV === "production";
Vue.config.productionTip = false;
Vue.config.devtools = true;

// Vue.use(firestorePlugin);

Vue.prototype.$APP_TITLE = process.env.VUE_APP_TITLE;

const options = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};

Vue.use(VueLogger, options);
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

//Vue.prototype.$getField = tariffields;
Vue.prototype.$getTooltip = tooltippsjson;

let mixin = {
  data: function() {
    return {};
  },
};

new Vue({
  mixins: [mixin],
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
