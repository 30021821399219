import { RepositoryFactory } from "../repositories/RepositoryFactory";

const basicRepository = RepositoryFactory.get("basic");

const basic = {
  namespaced: false,
  state: {
    error: null,
  },
  mutations: {
    error(state, status) {
      state.error = status;
    },
  },
  actions: {
    trackReferrerLinks({ commit }, payload) {
      return basicRepository
        .trackReferrerLinks(payload)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },
    insertGoogleVisit({ commit }, payload) {
      return basicRepository
        .insertGoogleVisit(payload)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },

    saveReferrer({ commit }, payload) {
      return basicRepository
        .saveReferrer(payload)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },
  },
  getters: {},
};

export default basic;
