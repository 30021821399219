import { RepositoryFactory } from "../repositories/RepositoryFactory";

const rechnerRepository = RepositoryFactory.get("rechner");

const rechner = {
  namespaced: false,
  state: {
    error: null,
  },
  mutations: {
    error(state, status) {
      state.error = status;
    },
  },
  actions: {
    getBerechnungErgebnis({ commit }, payload) {
      return rechnerRepository
        .getBerechnungErgebnis(payload)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },
    insertAbschluss({ commit }, payload) {
      return rechnerRepository
        .insertAbschluss(payload)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },
    updateAbschlussStep({ commit }, payload) {
      return rechnerRepository
        .updateAbschlussStep(payload)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },
    getRassen({ commit }) {
      return rechnerRepository
        .getRassen()
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },
    getRassenKatze({ commit }) {
      return rechnerRepository
        .getRassenKatze()
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },
    getStimmen({ commit }) {
      return rechnerRepository
        .getStimmen()
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },

    getLastBerechnungen({ commit }, payload) {
      return rechnerRepository
        .getLastBerechnungen(payload)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },
    getLastBerechnungenToken({ commit }, payload) {
      return rechnerRepository
        .getLastBerechnungenToken(payload)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },

    updateTarif({ commit }, payload) {
      return tarifRepository
        .updateTarif(payload)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },
  },

  getters: {},
};

export default rechner;
