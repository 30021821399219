export default {
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  created() {
    window.addEventListener("resize", this.updateWidth);
  },
  destroyed() {
    window.removeEventListener("resize", this.updateWidth);
  },
  methods: {
    updateWidth() {
      this.windowWidth = window.innerWidth;
    },
    // isMobile() {
    //   var weite = 0;
    //   weite = window.innerWidth;
    //   if (weite < 600) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    // isDektop() {
    //   var weite = 0;
    //   weite = window.innerWidth;
    //   // console.log("weite " + weite);
    //   if (weite >= 770) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },
  computed: {
    isMobile() {
      // var weite = 0;
      // weite = window.innerWidth;
      if (this.windowWidth < 780) {
        return true;
      } else {
        return false;
      }
    },
    isMobileM() {
      // var weite = 0;
      // weite = window.innerWidth;
      if (this.windowWidth < 1450) {
        return true;
      } else {
        return false;
      }
    },
    isDektop() {
      var weite = 0;
      weite = window.innerWidth;
      // console.log("weite " + weite);
      if (weite >= 770) {
        return true;
      } else {
        return false;
      }
    },
  },
};
