<template>
  <div class="ggfont pa-6">
    <h4>Online-Vergleich starten <sup class="green--text">Tipp</sup></h4>
    <ul>
      <li>
        <router-link to="/rechner">Hunde-Krankenversicherungen</router-link>
      </li>
      <li>
        <router-link to="/vergleich_katze"
          >Katzen-Krankenversicherungen</router-link
        >
      </li>
    </ul>
    <div class="divider"></div>

    <h4>Für Tierärzte</h4>
    <ul>
      <li v-if="!user.user_id">
        <router-link to="/tierarzt">Als Tierarzt anmelden</router-link>
        <br /><span class="caption"
          ><b class="redtext">Kostenlos</b> EisbaumTabellen bestellen</span
        >
      </li>
      <li v-if="user.user_id">
        <router-link to="/account">Neue Tabellen bestellen</router-link>
      </li>
      <li v-if="user.user_id">
        <router-link to="/stammdaten">Adresse & Passwort ändern</router-link>
      </li>
    </ul>
    <div class="divider"></div>
    <span v-if="empfehlung.ber_id">
      <h4 class="redtext">Eisbaum Empfehlung</h4>
      <rechner-name :berechnung="empfehlung" class="pb-2" />
      <ul>
        <li v-if="empfehlung.ber_emp_1_id > 0">
          {{ empfehlung.ber_emp_1_name }}
          <sup class="redtext">Empfehlung</sup>
        </li>
        <li v-if="empfehlung.ber_emp_2_id > 0">
          {{ empfehlung.ber_emp_2_name }} <sup>Alternative</sup>
        </li>
        <li>
          <a :href="`/vergleich/${empfehlung.ber_token}`">Ergebnis anzeigen</a>
        </li>
      </ul>
      <div class="divider"></div>
    </span>
    <span v-if="berechnungen.length > 0">
      <h3>Letzte Berechnungen</h3>
      <ul>
        <li class="mb-2" v-for="item in berechnungen" :key="item.ber_id">
          <a @click="item.showInfo = !item.showInfo">
            <rechner-name :berechnung="item" />
          </a>
          <span class="caption red--text" v-if="item.ber_error > 0"
            >Error Nr. {{ item.ber_error }}</span
          >
          <v-dialog v-model="item.showInfo" width="666">
            <rechner-vorgabe :berechnung="item" />
          </v-dialog>
        </li>
      </ul>
      <div class="divider"></div>
    </span>

    <ul>
      <li><router-link to="/impressum">Impressum</router-link></li>
    </ul>
  </div>
</template>

<script>
  import RechnerVorgabe from "./../../rechner/RechnerVorgabe";
  import RechnerName from "./../../rechner/RechnerName";
  export default {
    name: "NavDrawer",
    components: {
      RechnerVorgabe,
      RechnerName,
    },
    props: [],
    data() {
      return {
        dialog: false,
        user: false,
        berechnungen: false,
        empfehlung: false,

        token: this.$route.params.token,
        select: {
          guest_id: 0,
          token: "",
        },
      };
    },
    created() {
      if (this.token) {
        this.getLastBerechnungenOhne();
        //this.getLastBerechnungenToken(this.token);
      }
      if (!this.token) {
        // console.log("get last ohne token");
        this.getLastBerechnungen();
      }
      //this.getLastBerechnungen();
      this.checkLogin();
    },
    // watch: {
    //   "$route.params.token": {
    //     handler: function (search) {
    //       if (this.token) {
    //         console.log("route watch geändert", search);
    //         this.getLastBerechnungenToken(search);
    //       } else {
    //         console.log("normale route ohne token", search);
    //         //  this.getLastBerechnungen();
    //       }
    //     },
    //     deep: true,
    //     immediate: true,
    //   },
    // },
    methods: {
      checkLogin() {
        this.$log.debug("check app ob admin da");
        this.$log.debug("reload navdraww");
        this.$store.dispatch("apiUserGet", "checklogin").then((resp) => {
          this.$log.debug("checklogin app", resp);
          if (resp.data.user) {
            this.$store.commit("user", resp.data.user);
            this.user = resp.data.user;
          }
        });
      },
      changeRoute(route) {
        this.$router.push(route);
      },
      getLastBerechnungenOhne() {
        this.$store
          .dispatch("getLastBerechnungen", this.select)
          .then((resp) => {
            if (resp.data.berechnungen) {
              this.berechnungen = resp.data.berechnungen;
            }
          });
      },
      getLastBerechnungen() {
        this.$log.debug("getLastBerechnungen");
        this.$store
          .dispatch("getLastBerechnungen", this.select)
          .then((resp) => {
            if (resp.data.berechnungen) {
              //  this.$log.debug("getLastBerechnungen Ergb", resp.data.berechnungen);
              this.berechnungen = resp.data.berechnungen;
              this.empfehlung = resp.data.empfehlung;
              this.$log.debug("Empfehlung ", this.empfehlung.ber_id);
            }
          });
      },
      getLastBerechnungenToken(token) {
        this.$log.debug("berechnung token get", token);
        this.select.token = token;
        this.$store
          .dispatch("getLastBerechnungenToken", this.select)
          .then((resp) => {
            if (resp.data.empfehlung) {
              this.empfehlung = resp.data.empfehlung;
              this.$log.debug("Empfehlung ", this.empfehlung);
            }
          });
      },
    },
  };
</script>
