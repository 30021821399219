<template>
  <div>
    <v-card class="mb-8" elevation="0" style="background-color: #f2f2f2">
      <v-container v-if="user">
        <v-row>
          <v-col cols="12" md="12">
            <h2 class="green--text">Sie sind jetzt erfolgreich eingeloggt</h2>
          </v-col>
        </v-row>
      </v-container>
      <v-alert v-if="passSuccess" type="success" class="white--text"
        >Wir senden Ihnen an die E-Mail ein neues Passwort zu.</v-alert
      >
      <v-alert v-if="error" type="error" class="white--text"
        >Ihre E-Mail scheint falsch zu sein.</v-alert
      >
      <v-container v-if="!user && !passVergessen">
        <h2>Tierarzt-Login EisbaumTabelle.de</h2>
        <v-row dense>
          <v-col cols="12" md="10">
            <v-text-field v-model="select.email" label="E-Mail"></v-text-field>
            <v-text-field
              v-model="select.checkcheck"
              label="Passwort"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="10" class="pt-4"
            ><v-btn
              color="blue"
              @click="startLogin()"
              class="nocaps"
              outlined
              tile
              >jetzt einloggen</v-btn
            >
            <v-checkbox
              v-model="passVergessen"
              label="Ich habe meine Passwort
            vergessen"
            />

            <v-alert
              class="mt-4"
              border="top"
              colored-border
              type="error"
              color="red"
              elevation="2"
              v-if="fehler"
              ><h2>Ihre E-Mail oder das Passwort scheint falsch zu sein</h2>
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="!user && passVergessen">
        <h2>Neues Eisbaum-Passwort anfordern</h2>
        Bitte geben Sie Ihre E-Mail an die wir Ihr neues Passwort senden sollen.

        <v-text-field
          v-model="select.checkcheck"
          label="Ihre E-Mail"
        ></v-text-field>
        <v-btn
          v-if="select.checkcheck != ''"
          color="blue"
          @click="startPassVergessen()"
          class="nocaps"
          outlined
          tile
          >Jetzt hier klicken und ein neues Passwort anfordern</v-btn
        >
      </v-container>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: "index-login",
    components: {},

    data: function() {
      return {
        passVergessen: false,
        passSuccess: false,
        error: false,
        select: {
          email: "",
          checkcheck: "",
        },
        fehler: false,
        user: false,
      };
    },
    created() {},

    methods: {
      startPassVergessen() {
        this.select.action = "passforgot";
        this.$store.commit("start_loading_full");
        this.$store
          .dispatch("apiUserPost", this.select)
          .then((resp) => {
            this.$store.commit("stop_loading_full");
            if (resp.data.success) {
              this.passSuccess = true;
              this.passVergessen = false;
              this.error = false;
            } else {
              this.error = true;
            }
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },
      startLogin() {
        this.$store.commit("start_loading_full");
        this.select.action = "startlogin";
        this.fehler = false;
        this.$store
          .dispatch("apiUserPost", this.select)
          .then((resp) => {
            this.$store.commit("stop_loading_full");
            if (resp.data.user) {
              this.$store.commit("user", resp.data.user);
              this.user = resp.data.user;
              this.$log.debug("StoreUser", this.$store.getters.getUserStore);
              window.location.href = "/account";
              // this.$router.push("/account");
            } else {
              this.fehler = true;
            }
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },
    },
  };
</script>
