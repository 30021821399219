
import ChatRepository from "./ChatRepository";
import AuthRepository from "./AuthRepository";
import TarifRepository from "./TarifRepository";
import RechnerRepository from "./RechnerRepository";
import BasicRepository from "./BasicRepository";
import UserRepository from "./UserRepository";
import GuestRepository from "./GuestRepository";

const repositories = {

    chat: ChatRepository,
    auth: AuthRepository,
    tarif: TarifRepository,
    rechner: RechnerRepository,
    basic: BasicRepository,
    user: UserRepository,
    guest: GuestRepository,


};

export const RepositoryFactory = {
    get: name => repositories[name]
};
