import Repository from "./Repository";

const resource = "";

export default {
  getBerechnungErgebnis(payload) {
    if (payload.type == "hunde" || payload.type == "katze")
      return Repository.post(`${resource}/getberechnungergebnis/post`, {
        payload,
      });
    if (payload.type == "token")
      return Repository.post(`${resource}/getberechnungergebnistoken/post`, {
        payload,
      });
    if (payload.type == "tokentarif")
      return Repository.post(`${resource}/gettarifberechnung/post`, {
        payload,
      });
  },

  insertAbschluss(payload) {
    return Repository.post(`${resource}/insertabschluss/post`, {
      payload,
    });
  },
  updateAbschlussStep(payload) {
    return Repository.post(`${resource}/updateabschlussstep/post`, {
      payload,
    });
  },

  getRassen() {
    return Repository.get(`${resource}/getrassen/get`);
  },

  getRassenKatze() {
    return Repository.get(`${resource}/getrassenkatze/get`);
  },

  getStimmen() {
    return Repository.get(`${resource}/getstimmen/get`);
  },

  getLastBerechnungen(payload) {
    return Repository.post(`${resource}/getlastberechnungen/post`, { payload });
  },

  getLastBerechnungenToken(payload) {
    return Repository.post(`${resource}/getlastberechnungentoken/post`, {
      payload,
    });
  },
};
