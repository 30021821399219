import Repository from "./Repository";

const resource = "";

export default {
  apiTarifPost(payload) {
    return Repository.post(`${resource}/apitarifpost`, { payload });
  },
  getTarife(payload) {
    return Repository.post(`${resource}/gettarife/post`, { payload });
  },
  getTarifeUrl(payload) {
    return Repository.post(`${resource}/gettarifeurl/post`, { payload });
  },
  getTarifAntragFragen(payload) {
    return Repository.post(`${resource}/gettarifantragfragen`, { payload });
  },

  getRealwert(payload) {
    return Repository.post(`${resource}/getrealwert`, { payload });
  },
  getTarifBenchmark(payload) {
    return Repository.post(`${resource}/gettarifbenchmark`, { payload });
  },

  insertNeueFrage(payload) {
    return Repository.post(`${resource}/insertneuefrage`, { payload });
  },
  updateAntragFrage(payload) {
    return Repository.post(`${resource}/updateantragfrage`, { payload });
  },

  getTarifBerechnung(payload) {
    return Repository.post(`${resource}/gettarifberechnung`, { payload });
  },

  getTestberichte(payload) {
    return Repository.post(`${resource}/gettestberichte`, { payload });
  },

  getBeispiele(payload) {
    return Repository.post(`${resource}/getbeispiele`, { payload });
  },
  submitFileAvb(formData) {
    /*  console.log('file repo ', formData) */
    return Repository.post(`${resource}/file/upload_avb`, formData, {
      headers: {
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });
  },
  getAvb(payload) {
    return Repository.post(`${resource}/tarif/getavb`, { payload });
  },
  getPreise(payload) {
    if (payload.type == "alle")
      return Repository.post(`${resource}/getpreise/post`, { payload });
  },
  insertPreis(payload) {
    // console.log('repo', payload)
    return Repository.post(`${resource}/insertpreis/post`, { payload });
  },
  startInsertTest(payload) {
    // console.log('repo', payload)
    return Repository.post(`${resource}/startinserttest`, { payload });
  },

  startInsertBeispiel(payload) {
    // console.log('repo', payload)
    return Repository.post(`${resource}/startinsertbeispiel`, { payload });
  },

  insertNeuerBenchmark(payload) {
    // console.log('repo', payload)
    return Repository.post(`${resource}/insertneuerbenchmark`, { payload });
  },

  updateTarif(payload) {
    // console.log('repo', payload)
    return Repository.post(`${resource}/updatetarif/post`, { payload });
  },
};
