const IndexHome = () => import("./components/base/IndexHome");
const IndexHund = () => import("./components/hund/IndexHund");
const IndexKatze = () => import("./components/katze/IndexKatze");
const StartKatze = () => import("./components/katze/StartKatze");
const IndexAdmin = () => import("./components/hund/IndexAdmin");
const IndexPreise = () => import("./components/admin/IndexPreise");
const IndexAntragAdmin = () => import("./components/admin/IndexAntragAdmin");
const IndexWertAdmin = () => import("./components/admin/IndexWertAdmin");
const IndexRechner = () => import("./components/rechner/IndexRechner");
const IndexRechnerKatze = () =>
  import("./components/rechner/IndexRechnerKatze");
const IndexVergleich = () => import("./components/rechner/IndexVergleich");
const IndexAntrag = () => import("./components/rechner/IndexAntrag");
const IndexAbschluss = () => import("./components/rechner/IndexAbschluss");
const RegisterUser = () => import("./components/user/RegisterUser");
const UpdateUser = () => import("./components/user/UpdateUser");
const IndexAccount = () => import("./components/account/IndexAccount");
const IndexLogout = () => import("./components/account/IndexLogout");
const IndexImpressum = () => import("./components/info/IndexImpressum");
const IndexUeber = () => import("./components/info/IndexUeber");
const IndexUrlaub = () => import("./components/info/IndexUrlaub");
const IndexGot = () => import("./components/info/IndexGot");
const OpIndex = () => import("./components/info/OpIndex");
const SofortBestellung = () => import("./components/account/SofortBestellung");

const routes = [
  {
    path: "/",
    name: "starthund",
    component: IndexHome,
    meta: { guest: true },
  },
  {
    path: "/katze",
    name: "startkatze",
    component: StartKatze,
    meta: { guest: true },
  },
  {
    path: "/Op_Versicherung_Hund_Katze",
    name: "op_versicherung",
    component: OpIndex,
    meta: { guest: true },
  },
  {
    path: "/nachbestellung/:token",
    name: "SofortBestellung",
    component: SofortBestellung,
    meta: { guest: true },
  },
  {
    path: "/abschluss/:tarif_id/:token",
    name: "abschluss",
    component: IndexAbschluss,
    meta: { guest: true },
  },
  {
    path: "/ueber_die_eisbaumtabelle",
    name: "ueber",
    component: IndexUeber,
    meta: { guest: true },
  },
  {
    path: "/Gebuehrenordnung_fuer_Tieraerzte",
    name: "infogot",
    component: IndexGot,
    meta: { guest: true },
  },
  {
    path: "/Urlaub_mit_Hund",
    name: "urlaub",
    component: IndexUrlaub,
    meta: { guest: true },
  },
  {
    path: "/preise/:id",
    name: "preise",
    component: IndexPreise,
    meta: { guest: true },
  },
  {
    path: "/impressum",
    name: "impressum",
    component: IndexImpressum,
    meta: { guest: true },
  },

  {
    path: "/antragadmin/:id",
    name: "antragadmin",
    component: IndexAntragAdmin,
    meta: { guest: true },
  },

  {
    path: "/wertadmin/:id",
    name: "wertadmin",
    component: IndexWertAdmin,
    meta: { guest: true },
  },
  {
    path: "/admin/:id",
    name: "admin",
    component: IndexAdmin,
    meta: { guest: true },
  },

  {
    path: "/infopaket/:id/:token",
    name: "infopaket",
    component: IndexAntrag,
    meta: { guest: true },
  },

  {
    path: "/tierarzt",
    name: "tierarzt",
    component: RegisterUser,
    meta: { guest: true },
  },
  {
    path: "/account",
    name: "account",
    component: IndexAccount,
    meta: { guest: true },
  },
  {
    path: "/stammdaten",
    name: "stammdaten",
    component: UpdateUser,
    meta: { guest: true },
  },
  {
    path: "/logout",
    name: "logout",
    component: IndexLogout,
    meta: { guest: true },
  },
  {
    path: "/Krankenversicherung_Hund/:id",
    name: "hundeversicherung",
    component: IndexHund,
    meta: { guest: true },
  },
  {
    path: "/Krankenversicherung_Katze/:id",
    name: "katzenversicherung",
    component: IndexKatze,
    meta: { guest: true },
  },
  {
    path: "/rechner",
    name: "rechner",
    component: IndexRechner,
    meta: { guest: true },
  },
  {
    path: "/vergleich_katze",
    name: "vergleich_katze",
    component: IndexRechnerKatze,
    meta: { guest: true },
  },

  {
    path: "/vergleich/:token",
    name: "vergleich",
    component: IndexVergleich,
    meta: { guest: true },
  },
];

export default routes;
