import Repository from "./Repository";

const resource = "";
export default {
    updateGuest(payload) {
        return Repository.post(`${resource}/guest/update`, { payload });
    },
    apiGuestGet(action) {
        if (action == 'checkadmin') {
            return Repository.get(`${resource}/guest/checkadmin`);
        }
        if (action == 'getguestcookie') {
            return Repository.get(`${resource}/guest/getguestcookie`);
        }
        if (action == 'trackcheckguest') {
            return Repository.get(`${resource}/guest/trackcheckguest`);
        }
    },

    checkAdmin() {
        return Repository.get(`${resource}/guest/checkadmin`);
    },








}
