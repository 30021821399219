import { RepositoryFactory } from "../repositories/RepositoryFactory";

const tarifRepository = RepositoryFactory.get("tarif");

const tarif = {
  namespaced: false,
  state: {
    error: null,
  },
  mutations: {
    error(state, status) {
      state.error = status;
    },
  },
  actions: {
    getTarife({ commit }, payload) {
      return tarifRepository
        .getTarife(payload)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },
    getTarifeUrl({ commit }, payload) {
      return tarifRepository
        .getTarifeUrl(payload)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },

    updateAntragFrage({ commit }, payload) {
      return tarifRepository
        .updateAntragFrage(payload)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },

    insertNeueFrage({ commit }, payload) {
      return tarifRepository
        .insertNeueFrage(payload)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },

    getTarifAntragFragen({ commit }, payload) {
      return tarifRepository
        .getTarifAntragFragen(payload)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },
    getTarifBenchmark({ commit }, payload) {
      return tarifRepository
        .getTarifBenchmark(payload)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },
    getRealwert({ commit }, payload) {
      return tarifRepository
        .getRealwert(payload)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },

    apiTarifPost({ commit }, payload) {
      return tarifRepository
        .apiTarifPost(payload)
        .then((resp) => {
          commit("success");

          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },

    getTarifBerechnung({ commit }, payload) {
      return tarifRepository
        .getTarifBerechnung(payload)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },

    getPreise({ commit }, payload) {
      return tarifRepository
        .getPreise(payload)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },

    getTestberichte({ commit }, payload) {
      return tarifRepository
        .getTestberichte(payload)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },
    getAvb({ commit }, payload) {
      return tarifRepository
        .getAvb(payload)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },
    submitFileAvb({ commit }, { formData }) {
      /*   console.log('store upload', formData) */
      // commit('start_loading_full')
      return tarifRepository
        .submitFileAvb(formData)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {
          // TODO: Attention!!! delay response
          setTimeout(() => {
            // commit('stop_loading_full')
          }, 3000);
        });
    },
    getBeispiele({ commit }, payload) {
      return tarifRepository
        .getBeispiele(payload)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },

    insertPreis({ commit }, payload) {
      return tarifRepository
        .insertPreis(payload)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },

    startInsertBeispiel({ commit }, payload) {
      return tarifRepository
        .startInsertBeispiel(payload)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },

    startInsertTest({ commit }, payload) {
      return tarifRepository
        .startInsertTest(payload)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },

    insertNeuerBenchmark({ commit }, payload) {
      return tarifRepository
        .insertNeuerBenchmark(payload)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },

    updateTarif({ commit }, payload) {
      return tarifRepository
        .updateTarif(payload)
        .then((resp) => {
          commit("success");
          return resp;
        })
        .catch((err) => {
          commit("error");
          return err;
        })
        .finally(() => {});
    },
  },

  getters: {},
};

export default tarif;
