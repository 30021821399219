<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <!-- <v-btn
          v-if="!watchuser"
          @click="showLogin = !showLogin"
          tile
          outlined
          color="blue"
          class="button_caps"
          >login</v-btn
        > -->

        <span class="ml-8" style="font-size: 18px">
          <a href="/" class="mx-4">Home</a>

          <a
            href="/account"
            class="mx-4"
            v-if="$store.getters.getUserStore.user_id > 0"
            ><b>neue Tabellen bestellen</b>
          </a>

          <a href="/rechner" class="mx-4">Vergleich starten</a>

          <a href="/ueber_die_eisbaumtabelle" class="mx-4"
            >über die EisbaumTabelle</a
          >

          <a
            @click="startLogout()"
            class="mx-4"
            v-if="$store.getters.getUserStore.user_id"
            >logout</a
          >
          <a
            href="/tierarzt"
            v-if="!$store.getters.getUserStore.user_id"
            class="mx-4"
            >für Tierärzte</a
          >
          <a
            class="mx-4"
            v-if="!$store.getters.getUserStore.user_id"
            @click="showLogin = !showLogin"
            >Tierarzt Login</a
          >
        </span>
      </v-col>
    </v-row>

    <v-dialog v-model="showLogin" max-width="800">
      <v-card class="pa-4"> <index-login /></v-card>
    </v-dialog>
  </div>
</template>

<script>
  import IndexLogin from "./IndexLogin.vue";
  import { mapState, mapMutations } from "vuex";

  export default {
    name: "AppBar",
    props: [],
    components: { IndexLogin },
    data: function() {
      return {
        user: false,
        showLogin: false,
      };
    },
    created() {},
    methods: {
      ...mapMutations("nav", [
        "showDrawer",
        "hideDrawer",
        "minimizeDrawer",
        "maximizeDrawer",
      ]),

      startLogout() {
        this.$log.debug("startlogout");
        this.$emit("clicked", "someValue");
      },
    },
    computed: {
      // watchuser() {
      //   return this.$store.getters.getUserStore;
      // },
      ...mapState("nav", ["drawerVisible", "miniVariant"]),
    },
  };
</script>
<style></style>
