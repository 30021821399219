<template>
  <div id="app">
    <v-app>
      <v-overlay
        :absolute="absolute"
        :opacity="opacity"
        :value="$store.getters.isLoadingFull"
      >
        <v-btn color="orange lighten-2" @click="overlay = false"
          >wird geladen....please wait</v-btn
        >
      </v-overlay>
      <div
        v-if="scrollVisible"
        style="background-color:#f2f2f2;height:40px;  position: fixed;width: 100%;z-index:100;"
        class="pa-2"
      >
        <span
          v-if="!isMobile"
          style="font-weight:normal;ffont-size:18px;height:30px;"
          class="mt-0 pa-0 greytext"
          >&nbsp;&nbsp;<b>Das Original</b> | von Tierärzten empfohlen
        </span>
      </div>

      <v-app-bar
        v-scroll="makeSmall"
        app
        clipped-left
        flat
        :style="styleSwitch"
        style="border-bottom: 4px solid #f2f2f2;background-color:#fff"
      >
        <v-app-bar-nav-icon
          @click="showMenue = !showMenue"
          style="font-size: 48px"
        ></v-app-bar-nav-icon>
        <v-app-bar-title style="wdith:340px;" class="custom-app-bar-title">
          <h1 class="mt-0">
            <a href="/" class="ggfontbold redtext"
              >EisbaumTabelle
              <span v-if="!isMobile" style="font-weight:normal;">
                | 2024</span
              ></a
            >
          </h1>
        </v-app-bar-title>

        <v-spacer></v-spacer>
        <v-btn
          v-if="!isMobileM"
          to="/rechner"
          density="compact"
          outlined
          class="nocaps borderbtn"
          ><v-icon class="redtext mr-2">pets</v-icon> Hunde-Vergleich</v-btn
        >

        <v-btn
          v-if="!isMobileM"
          to="/vergleich_katze"
          density="compact"
          outlined
          class="nocaps ml-4 borderbtn"
          ><v-icon class="katzetext mr-2">pets</v-icon> Katzen-Vergleich</v-btn
        >
        <v-btn
          v-if="!isMobileM"
          density="compact"
          outlined
          class="nocaps ml-4 borderbtn"
          ><v-icon class="greytext mr-2">apps</v-icon>
          <a href="/#EBW" class="greytext">Realwert-Methode</a></v-btn
        >

        <v-btn
          v-if="!$store.getters.getUserStore.user_id && !isMobile"
          density="compact"
          outlined
          class="nocaps ml-4 borderbtn"
          to="/tierarzt"
          ><v-icon class="redtext mr-2">add</v-icon> für Tierärzte</v-btn
        >

        <v-btn
          v-if="$store.getters.getUserStore.user_id && !isMobile"
          density="compact"
          outlined
          class="nocaps ml-4 borderbtn"
          to="/account"
          ><v-icon class="redtext mr-2">shopping_cart</v-icon> EisbaumTabbellen
          anfordern</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          v-if="!$store.getters.getUserStore.user_id && !isMobile"
          @click="showLogin = !showLogin"
          class="nocaps"
          style="background-color: #d84315; color: #fff"
          >Tierarzt Login</v-btn
        >

        <v-btn
          v-if="$store.getters.getUserStore.user_id && !isMobile"
          @click="reloadLogout()"
          class="nocaps"
          style="background-color: #d84315; color: #fff"
          >Logout</v-btn
        >
      </v-app-bar>

      <v-navigation-drawer
        style="margin-top:30px;"
        v-if="showMenue"
        v-model="showMenue"
        app
        clipped
        floating
      >
        <NavDrawer :key="componentKey" />
      </v-navigation-drawer>

      <div
        v-if="!showMenue && !isMobileM"
        class="pa-4"
        style="position: fixed;
  top: 120px;
  left: 0;z-index:1000; width:160px;"
      >
        <h3>Rechner starten</h3>
        Starten Sie jetzt einen Online-Vergleich. In nur 1 Minute erhalten Sie
        Ihr Ergebnis.
        <v-btn
          to="/rechner"
          density="compact"
          outlined
          class="nocaps mt-4 borderbtn"
          ><v-icon class="redtext mr-2">pets</v-icon> Hund</v-btn
        >
        <v-btn
          to="/vergleich_katze"
          density="compact"
          outlined
          class="nocaps mt-4 borderbtn"
          ><v-icon class="katzetext mr-2">pets</v-icon> Katze</v-btn
        >
      </div>

      <v-main style="margin-top:20px;">
        <v-container fill-height>
          <v-row align="center" justify="center">
            <v-col cols="12" md="12">
              <router-view></router-view>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
      <v-dialog v-model="showLogin" max-width="800">
        <v-card class="pa-4"> <index-login /></v-card>
      </v-dialog>
      <v-footer>
        <div class="pa-2">
          <router-link to="/ueber_die_eisbaumtabelle" class="headerlink"
            ><a>Über die EisbaumTabelle</a></router-link
          >
          |
          <router-link to="/impressum" class="headerlink"
            ><a>Impressum</a></router-link
          >
        </div>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
  import AppBar from "./components/base/helper/AppBar";
  import NavDrawer from "./components/base/helper/NavDrawer";
  import baseMixin from "./components/mixins/baseMixin";
  import IndexLogin from "./components/base/helper/IndexLogin.vue";

  export default {
    name: "index-home",
    mixins: [baseMixin],
    components: {
      AppBar,
      NavDrawer,
      IndexLogin,
    },
    data() {
      return {
        windowWidth: window.innerWidth,
        componentKey: 0,
        scrollPosition: 0,
        guest: false,
        user: false,
        styleSwitch: "margin-top:40px;",
        scrollVisible: true,

        showMenue: false,

        absolute: true,
        opacity: 1,
        overlay: false,
        check: 0,
        myclient: false,
        allclients: false,
        myhistory: false,
        dialog: false,
        showLogin: false,
        drawer: null,
        items: [
          { icon: "contacts", text: "Contacts" },
          { icon: "history", text: "Frequently contacted" },
        ],
      };
    },
    computed: {
      isMobileApp() {
        if (this.windowWidth < 600) {
          return true;
        } else {
          return false;
        }
      },
    },

    created() {
      window.addEventListener("resize", this.onResize);
      this.checkLogin();
      this.checkLinkReferrer();
      // if (!this.isMobile) {
      //   this.showMenue = true;
      // }
      this.$log.debug(
        "$store.getters.isLoadingFull",
        this.$store.getters.isLoadingFull
      );
      if (this.windowWidth < 600) {
        this.$store.commit("setIsMobile", false);
        this.makeSmall();
      }
    },
    destroyed() {
      window.removeEventListener("resize", this.onResize);
    },
    mounted() {
      this.$nextTick(function() {
        setTimeout(this.getGuestCookie, 1000);
      });
    },

    watch: {},

    methods: {
      makeSmall() {
        const currentScrollPosition =
          window.pageYOffset || document.documentElement.scrollTop;
        //this.$log.debug("scroll position", currentScrollPosition);
        if (currentScrollPosition > this.scrollPosition) {
          this.styleSwitch = "margin-top:0px;";
          this.scrollVisible = false;
          //this.$log.debug("scrolldown");
        } else {
          this.styleSwitch = "margin-top:40px;";
          this.scrollVisible = true;
          //this.$log.debug("scrollup");
        }
      },
      handleScroll(e) {
        // Überprüfen Sie die Scrollposition
        if (e.target.scrollTop > 10) {
          this.$log.debug("handle scroll aus ");
          this.scrollVisible = false; // Verstecken Sie das div, wenn die Scrollposition größer als 100 ist (Sie können diesen Wert anpassen)
        } else {
          this.$log.debug("handle scroll ein ", e.target.scrollTop);
          this.scrollVisible = true; // Zeigen Sie das div wieder, wenn die Scrollposition kleiner oder gleich 100 ist
        }
      },
      onResize(e) {
        this.windowWidth = window.innerWidth;
      },
      checkLinkReferrer() {
        var checkStringGoogle = document.referrer,
          substring1 = ".google";
        var isGoogleLink = checkStringGoogle.indexOf(substring1) !== -1;

        var checkStringOur = document.referrer,
          substring2 = ".eisbaum";
        var isOurLink = checkStringOur.indexOf(substring2) !== -1;

        var checkStringLocal = document.referrer,
          substring3 = "local";
        var isLocalLink = checkStringLocal.indexOf(substring3) !== -1;

        if (isGoogleLink) {
           this.$log.error("Track GG:");
           
            try {
              this.$store.dispatch("insertGoogleVisit", window.location.href);
            } catch (error) {
              this.$log.error("Error inserting Google visit:", error);
            }
      }


        if (!isGoogleLink && !isOurLink && !isLocalLink) {
          this.$log.debug("sendReflink", document.referrer);
          this.$store.dispatch("trackReferrerLinks", document.referrer);
        }
      },
      reloadLogout() {
        this.$store.commit("start_loading_full");
        this.$store
          .dispatch("apiUserGet", "startlogout")
          .then((resp) => {
            if (resp.data.success) {
              this.$store.commit("user", false);
              this.$store.commit("stop_loading_full");
              this.componentKey += 1;
              this.$router.push("/logout");
            }
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },
      checkLogin() {
        this.$log.debug("check app ob admin da");
        this.$store.dispatch("apiUserGet", "checklogin").then((resp) => {
          this.$log.debug("checklogin app", resp);
          if (resp.data.user) {
            this.$store.commit("user", resp.data.user);
            this.user = resp.data.user;
          }
        });
      },
      getGuestCookie() {
        this.$log.debug("check app ob cookie da");
        this.$store.dispatch("apiGuestGet", "getguestcookie").then((resp) => {
          if (resp.data.check > 0) {
            this.check = resp.data.check;
            this.$log.debug("check app insert", this.check);
          } else {
            this.trackCheckGuest();
          }
          // if (this.windowWidth < 600) {
          //   this.$store.commit("setIsMobile", false);
          // }
          this.componentKey += 1;
        });
      },
      trackCheckGuest() {
        this.$log.debug("trachCheck app insert");
        this.$store.dispatch("apiGuestGet", "trackcheckguest").then((resp) => {
          if (resp.data.guest) {
            this.guest = resp.data.guest;
          }
        });
      },
    },
  };
</script>

<style>
  .scroll-content {
    /* Stil und Übergangseffekte für das scrollende div */
    max-height: 500px; /* Geben Sie hier die maximale Höhe an */
    overflow-y: auto;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  }

  .scroll-content.hide {
    max-height: 0;
    opacity: 0;
    transition: max-height 0.3s ease-in, opacity 0.3s ease-in;
  }
  .nocaps {
    text-transform: none !important;
  }
  .smallcaps {
    text-transform: none !important;
    letter-spacing: normal !important;
    font-size: 20px;
  }
  .smalltext {
    font-size: 12px;
  }
  .greytext {
    color: #555555 !important;
  }
  .lightgreytext {
    color: #f2f2f2 !important;
  }
  .textnormal {
    font-size: 16px;
  }
  .redtext {
    color: #d84315 !important;
  }
  .katzetext {
    color: #fecf0c !important;
  }
  .ggfont {
    /* font-family: "Lato", sans-serif; */
    /* font-family: "Source Sans Pro", sans-serif; */
    font-family: "Roboto", sans-serif;
  }
  .ggfontbold {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
  }
  .ggfontitalic {
    font-family: "Roboto", sans-serif;
    font-style: italic;
  }
  .ggfonthigh {
    font-family: "Roboto", sans-serif;

    color: #d84315 !important;
  }
  .title_strong {
    font-size: 20px;
    color: #555555 !important;
    font-family: "Roboto", sans-serif;
    font-style: italic;
  }

  .headerlink {
    font-family: "Roboto", sans-serif;
    text-decoration: none;
    color: #555555 !important;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
  }
  .redground {
    background-color: #d84315;
  }
  .borderbtn {
    border: 2px solid #cccccc;
  }
  .katzeground {
    background-color: #fecf0c;
  }
  .greyground {
    background-color: #f2f2f2;
  }
  a:link {
    text-decoration: none;
  }
  .divider {
    border-bottom: 1px solid #c5c5c5 !important;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .dividersmall {
    border-bottom: 1px solid #c5c5c5 !important;
    margin-top: 6px;
    margin-bottom: 6px;
  }
  b {
    color: #555555;
  }
  h3 {
    font-size: 18px;
    color: #555555;
  }
  h2 {
    font-size: 22px;
    color: #555555;
  }
</style>
