import Repository from "./Repository";

const resource = "";

export default {
  saveReferrer(payload) {
    return Repository.post(`${resource}/basic/savereferrer`, { payload });
  },
  trackReferrerLinks(payload) {
    return Repository.post(`${resource}/basic/trackreferrerlinks`, { payload });
  },
  insertGoogleVisit(payload) {
    return Repository.post(`${resource}/basic/insertgooglevisit`, { payload });
  },
};
